import * as React from "react"
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import {graphql, useStaticQuery, Link} from "gatsby"
import BlogPostHeroImage from "../components/blog/BlogPostHeroImage"

const BlogPage = () => {
    const {blog} = useStaticQuery(graphql`
        query BlogPageQueryQ1 {
            blog: allMdx(sort: {fields: frontmatter___date, order: DESC}) {
                posts: nodes {
                    slug
                    id
                    hero: heroImage {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                    meta: frontmatter {
                        title
                        herourl
                    }
                    excerpt
                    tableOfContents
                }
            }
        }
    `)
    return (
        <Layout title={"Blog | Yachting AI"}
                canonical={"/blog/"}
                breadcrumbs={[{url: "/", name: "Home"}, {url: "/blog", name: "Blog"}]}
                breadcrumbsJustMeta={true}>

            <Seo title="Blog"
                 description="Yachting AI Blog"
                 canonical={`/blog/`}
                 image={`/hero-image.png`}
            />
            <section className={"px-6 pt-20 pb-10 max-container"}>
                <span>Yachting News</span>
                <h1 className={"text-var-3xl block font-bold"}>Latest Yachting Tech and AI Innovations in Luxury Boating</h1>
            </section>

            <section className="px-6 py-5 max-container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {blog?.posts?.map(post => (
                <article key={post.slug} className={"hover:bg-gray-200"}>
                    <Link to={"/blog/" + post.slug + '/'}>
                        <BlogPostHeroImage blog={post} className={"w-full h-48 object-cover rounded-md"} />
                        <h2 className={"text-var-2xl font-bold text-left line-clamp-2"}>{post.tableOfContents?.items[0]?.title || post.meta.title}</h2>
                        <p className={"text-sm line-clamp-3"}>{post.excerpt}</p>
                    </Link>
                </article>
            ))}
            </section>
        </Layout>)
}

export default BlogPage
